<template>
  <div class="query_container">
    <span><fa icon="search" class="orange"/></span>
    <input
      class="query"
      :value="value"
      @input="$emit('input', $event.target.value)"
      v-focus
      placeholder="Szukaj instrukcji"
    />
    <span class="after orange">SZUKAJ</span>
  </div>
</template>

<script>
export default {
  name: "SearchInput",
  props: {
    value: String
  },
  directives: {
    focus: {
      inserted: function(el) {
        el.focus()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.query_container {
  display: flex;
  width: 560px;
  max-width: 80%;
  min-width: 280px;
  padding: 0px 20px;
  height: 60px;
  background-color: white;
  border-radius: 30px;
  align-items: center;
  box-shadow: 1px 2px 3px 0 #000;
}

.query {
  flex-basis: 100%;
  height: 100%;
  padding: 0px 20px;
  border: none;
  outline: none;
}

.orange {
  color: #f67620;
}

.after {
  font-size: 0.8em;
  font-weight: bold;
}
</style>
