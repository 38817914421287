<template>
  <div class="home_container">
    <div class="flex_container">
      <TheNavbar center contact />
      <div class="header">
        Potrzebujesz instrukcji?<br />
        To się świetnie składa!
      </div>
      <SearchInput v-model="query" @input="toResults()" />
      <CategoryTileBar
        v-if="categories"
        :categories="categories"
        class="tileBar"
      />
    </div>
  </div>
</template>

<script>
import SearchInput from "@/components/SearchInput.vue"
import CategoryTileBar from "@/components/CategoryTileBar.vue"
import TheNavbar from "@/components/TheNavbar.vue"
import { mapActions } from "vuex"

export default {
  name: "Home",
  components: {
    SearchInput,
    CategoryTileBar,
    TheNavbar
  },
  data() {
    return {
      query: "",
      categories: []
    }
  },
  methods: {
    ...mapActions(["fetchAllCategories"]),
    toResults() {
      if (this.query.length >= this.$minSearchChars)
        this.$router.push({
          path: `/search/${encodeURIComponent(this.query)}`
        })
    }
  },
  created() {
    console.log("[HOME] loading categories to show...")
    this.fetchAllCategories().then(categories => {
      console.log("[HOME] categories loaded", categories)
      this.categories = categories
    })
  }
}
</script>

<style lang="stylus" scoped>
.home_container {
  background: url('~@/assets/background.svg') no-repeat 85% bottom;
  background-size: cover;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  min-width: 320px;

  @media screen and (min-width: 500px) {
    background: url('~@/assets/background-lg.svg') no-repeat center 55%;
    background-size: cover;
  }
}

.flex_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 1280px;
  min-height: 80vh;
  margin: auto;

  @media screen and (min-width: 600px) {
    padding: 0.5em;
  }

}

.header {
  max-width: 600px;
  font-size: 2.5em;
  font-size: calc(1.5em + 1vw);
  margin-top: 10vh;
  margin-bottom: 0.5em;
  display: inline-flex;

  @media screen and (min-width: 600px) {
    margin-top: 20vh;
    font-size: 2.5em;
  }
}

.tileBar {
  margin-top: 10vh;
  display: inline-flex;
  z-index: 2;
}

.title {
  font-size: 1.7em;
  margin-top: 0.5em;
}
</style>
