<template>
  <div class="tile_bar_container">
    <span class="header">Możesz też znaleźć instrukcję według kategorii</span>
    <div class="bar">
      <CategoryTile
        v-for="category in categories"
        :category="category"
        :key="category.id"
      />
    </div>
  </div>
</template>

<script>
import CategoryTile from "@/components/CategoryTile.vue"

export default {
  name: "TileBar",
  props: {
    categories: {
      required: true,
      type: Array
    }
  },
  components: {
    CategoryTile
  },
  data() {
    return {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.tile_bar_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  max-width: 100vw;
}

.header {
  font-size: 1.3em;
  font-weight: bold;
}
</style>
