export class Model {
  constructor(
    id,
    categoryId,
    name,
    producer,
    code,
    image,
    producerLogo,
    modelPath,
    time,
    numberOfSteps
  ) {
    this.id = id
    this.categoryId = categoryId
    this.name = name
    this.producer = producer
    this.code = code
    this.image = image
    this.producerLogo = producerLogo
    this.modelPath = modelPath
    this.time = time
    this.numberOfSteps = numberOfSteps
  }

  transformTextData(fn) {
    return new Model(
      this.id,
      this.categoryId,
      fn(this.name),
      fn(this.producer),
      fn(this.code),
      this.image,
      this.producerLogo,
      this.modelPath,
      this.time,
      this.numberOfSteps
    )
  }
}
// export default {}
