<template>
  <div class="search_container">
    <div v-if="models" class="flex_container">
      <TheNavbar fallback />
      <div class="header">Wyniki wyszukiwania ({{ filtered.length }})</div>
      <SearchInput v-model="query" />
      <div class="results">
        <ModelThumbnailTile
          v-for="model in filtered"
          :key="model.id"
          :model="model"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SearchInput from "@/components/SearchInput.vue"
import ModelThumbnailTile from "@/components/ModelThumbnailTile.vue"
import TheNavbar from "@/components/TheNavbar.vue"
import { mapActions } from "vuex"

export default {
  name: "Search",
  components: {
    SearchInput,
    ModelThumbnailTile,
    TheNavbar
  },
  props: {
    queryInput: String
  },
  data() {
    return {
      query: this.queryInput,
      models: null
    }
  },
  watch: {
    query(value) {
      if (this.query.length)
        this.$router.replace({
          path: `${encodeURIComponent(value)}`
        })
      else
        this.$router.replace({
          name: `home`
        })
    }
  },
  computed: {
    filtered() {
      console.log("calculating results")
      return this.models
        .filter(m =>
          this.query
            ? [m.name, m.producer, m.code].some(f =>
                f.toLowerCase().includes(this.query.toLowerCase())
              )
            : []
        )
        .map(m =>
          m.transformTextData(str =>
            str.replace(
              new RegExp(this.query, "ig"),
              `<span class="highlight">$&</span>`
            )
          )
        )
    }
  },
  methods: {
    ...mapActions(["fetchAllModels"])
  },
  created() {
    console.log("[SEARCH] loading models to show...")
    this.fetchAllModels().then(models => {
      console.log("[SEARCH] models loaded", models)
      this.models = models
    })
  }
}
</script>

<style lang="stylus" scoped>
.flex_container {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  max-width: 1280px;
  min-height: 80vh;
  margin: auto;

  @media screen and (min-width: 600px) {
    padding: 0.5em 2em;
  }
}

.title {
  display: inline-flex;
}

.header {
  max-width: 600px;
  font-size: 2.5em;
  margin-top: 0.2em;
  margin-bottom: 0.5em;
  display: inline-flex;

  @media screen and (min-width: 600px) {
    margin-top: 2em;
  }
}

.results {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
