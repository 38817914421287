<template>
  <div id="app">
    <transition
      mode="out-in"
      :name="transitionName"
      @before-leave="startTransition"
      @after-enter="finishTransition"
    >
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      prevHeight: 0,
      transitionName: "none"
    }
  },
  methods: {
    startTransition() {
      this.$transitionEvents.startTransition()
    },
    finishTransition() {
      this.$transitionEvents.finishTransition()
    }
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      let navDiff = to.meta.transitionOrder - from.meta.transitionOrder
      this.$history.navigationOccured(navDiff)
      this.transitionName = navDiff > 0 ? "slide-fade-left" : "slide-fade-right"
      next()
    })
  }
}
</script>

<style lang="stylus">
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/ubuntu-v14-latin_latin-ext-regular.eot');
  src: local('Ubuntu Regular'), local('Ubuntu-Regular'), url('fonts/ubuntu-v14-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), url('fonts/ubuntu-v14-latin_latin-ext-regular.woff2') format('woff2'), url('fonts/ubuntu-v14-latin_latin-ext-regular.woff') format('woff'), url('fonts/ubuntu-v14-latin_latin-ext-regular.ttf') format('truetype'), url('fonts/ubuntu-v14-latin_latin-ext-regular.svg#Ubuntu') format('svg');
}

body {
  font-family: Ubuntu, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  background-color: #292929;
  margin: 0;
  padding: 0;
}

.rounded {
  display: block;
  border-radius: 10px;
}

.highlight {
  color: #f67620;
}

.tile {
  @extend .rounded;
  background-color: white;
}

div::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #787b7f;
}

div::-webkit-scrollbar {
  border-radius: 8px;
  width: 4px;
  height: 4px;
  background-color: #787b7f;
}

div::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #f67620;
}

.slide-fade-right-enter-active, .slide-fade-right-leave-active, .slide-fade-left-enter-active, .slide-fade-left-leave-active {
  transition: all 0.1s ease-in-out;
}

.slide-fade-right-enter, .slide-fade-left-leave-to {
  transform: translateX(-50vw);
  opacity: 0;
}

.slide-fade-left-enter, .slide-fade-right-leave-to {
  transform: translateX(50vw);
  opacity: 0;
}

@keyframes jump {
  0%   {top: -1rem;}
  60%  {top: -0.66rem;}
  100% {top: 0;}
}

.jumping {
  transform-origin: 50% 50%;
  animation: jump .4s linear alternate infinite;
}

.tooltip {
    display: block !important;
    z-index: 500;

    .tooltip-inner {
      font-size: 1.1rem;
      background: rgba(255,255,255,0.9);
      color: #444;
      padding: 15px;
      border-radius: 10px;
      border-bottom: 1px solid rgb(246, 118, 32);
      box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.4);
    }

    .tooltip-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 10px;
      border-color: rgb(246, 118, 32);
      margin: 0;
    }

    &[x-placement^="top"] {
      margin-bottom: 10px;

      .tooltip-arrow {
        border-width: 10px 10px 0 10px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -10px;
      }
    }

    &[x-placement^="bottom"] {
      margin-top: 10px;

      .tooltip-arrow {
        border-width: 0 10px 10px 10px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        top: -10px;
      }
    }

    &[x-placement^="right"] {
      margin-left: 10px;

      .tooltip-arrow {
        border-width: 10px 10px 10px 0;
        border-left-color: transparent !important;
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
        left: -10px;
      }
    }

    &[x-placement^="left"] {
      margin-right: 10px;

      .tooltip-arrow {
        border-width: 10px 0 10px 10px;
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        right: -10px;
      }
    }

    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition: opacity .15s, visibility .15s;
    }

    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      transition: opacity .15s;
    }
}
</style>
