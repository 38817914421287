<template>
  <div :class="[{ center: center }, 'navbar-container']">
    <div class="navigation">
      <div
        class="back"
        v-if="!center && ($history.canGoBack() || fallback)"
        @click="$history.back(fallback)"
      >
        <fa icon="long-arrow-alt-left"></fa>
      </div>
    </div>
    <div class="logo">
      <img :src="require('@/assets/logo.png')" />
    </div>
    <ContactInfo v-if="contact" v-model="contactInfoVisible" class="contact"></ContactInfo>
  </div>
</template>

<script>
import ContactInfo from "../components/ContactInfo"

export default {
  components: {
    ContactInfo
  },
  props: {
    center: Boolean,
    contact: Boolean,
    fallback: Boolean
  },
  data() {
    return {
      contactInfoVisible: false
    }
  }
}
</script>

<style lang="stylus" scoped>
.navbar-container {
  margin-top: 0.5em;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;

  &.center {
    @media screen and (min-width: 500px) {
      justify-content: center;
    }
  }

  >.logo{
    position: relative;
    max-width: 70vw;
    >img {
        max-width: 100%;
        max-height: 100px;
    }
    &::after {
      position: absolute;
      content: 'wersja testowa';
      text-transform: uppercase;
      font-weight: bold;
      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
      left: 28%;
      bottom: 9%;
      font-size: "min(4vw, 18px)" % null;
    }
  }

  >.navigation {
    >.back, .forward {
      cursor: pointer;
      font-size: 50px;
      margin: -7px 20px 0px 0px;
    }

    .back {
    }
  }
}

a:link, a:hover, a:active, a:a:visited {
  text-decoration: none;
  color: #fff;
}
</style>
