<template>
  <div>
    <!-- @mouseover="switchVisibility(true)" @mouseout="switchVisibility(false)"  -->
    <!--  -->
    <div class="circle" @click="switchVisibility(true)">
      <span><fa :icon="['far', 'envelope']" /></span>
    </div>
    <transition name="slide-fade">
      <div class="overlay" v-if="value" @click="switchVisibility(false)">
        <div class="info-window tile">
          <div class="header">
            <div class="title">Informacje kontaktowe</div>
          </div>
          <div class="content">
            Aplikacja ININ została zaprojektowana <br />
            w firmie Venit sp. z o.o. z siedzibą <br />
            we Wrocławiu.
          </div>
          <div class="content">
            <a class="link" href="mailto:sales@venit3d.pl">sales@venit3d.pl</a>
          </div>
          <div class="content">tel. <a class="link" href="tel:+48 695 68 02 48">+48 695 68 02 48</a></div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "ContactInfo",
  props: {
    value: Boolean
  },
  methods: {
    switchVisibility(value) {
      this.$emit("input", value)
    }
  }
}
</script>

<style lang="stylus" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  opacity: 0;
}

.info-window {
  color: #5b5b5b;
  display: grid;
  grid-template-rows: 70px auto;
  grid-template-columns: 100%;
  grid-template-areas: 'head' 'cont';
  min-height: 310px;
  width: 500px;
  max-width: 90vw;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.5);
  overflow: hidden;

  .header {
    grid-area: head;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 5px 5px 0px #dedede;

    >.title {
      font-weight: bold;
      font-size: 1.2em;
      color: #5b5b5b;
      padding-bottom:0.5em;
      text-align: center;
    }
  }
}

a.link {
  color: #f4741e;
  margin: 5px;
}

a.link:hover {
  color: lighten(#f4741e, 30%);
}

.content {
  padding: 10px;
  font-size: 1.2em;
}

.circle {
  position: absolute;
  top: 30px;
  right: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  z-index: 98;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  height: 60px;
  width: 60px;
  background-color: #f4741e;
  transition: 0.2s;
  text-align: center;
}

.circle:hover {
  cursor: pointer;
  background-color: lighten(#f4741e, 20%);
}

.circle span {
  font-size: 1.5em;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
</style>
