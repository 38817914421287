import Vue from "vue"
import Vuex from "vuex"
import { Model } from "@/data/models.js"
import { fetchAllCategories } from "@/services/CategoriesService"
import { fetchAllModels } from "@/services/ModelsService"
import { Promise } from "core-js"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    models: [],
    categories: {}
  },
  mutations: {
    setCategory(state, category) {
      Vue.set(state.categories, category.id, category)
    },
    setModel(state, model) {
      Vue.set(state.models, model.id, model)
    }
  },
  actions: {
    fetchAllCategories({ state, commit }) {
      return new Promise((resolve, reject) => {
        const categories = fetchAllCategories()
        Object.values(categories).forEach(c => {
          commit("setCategory", c)
        })
        resolve(Object.values(state.categories))
      })
    },
    fetchAllModels({ state, commit }) {
      return new Promise((resolve, reject) => {
        const models = fetchAllModels()
        Object.values(models).forEach(m => {
          commit("setModel", toModel(m))
        })
        resolve(Object.values(state.models))
      })
    }
  }
})

function toModel(m) {
  return new Model(
    m.id,
    m.categoryId,
    m.name,
    m.producer,
    m.code,
    m.image,
    m.producerLogo,
    m.modelPath,
    m.time,
    m.numberOfSteps
  )
}
