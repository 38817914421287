<template>
  <div class="search_container">
    <div v-if="models && category" class="flex_container">
      <TheNavbar />
      <div class="header">Modele z kategorii {{ category.name }}</div>
      <div class="results">
        <ModelThumbnailTile
          v-for="model in categoryModels"
          :key="model.id"
          :model="model"
        />
        <div v-if="categoryModels.length == 0">
          Brak modeli w tej kategorii.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchInput from "@/components/SearchInput.vue"
import ModelThumbnailTile from "@/components/ModelThumbnailTile.vue"
import TheNavbar from "@/components/TheNavbar.vue"
import { mapActions } from "vuex"

export default {
  name: "CategoryModels",
  components: {
    ModelThumbnailTile,
    TheNavbar
  },
  props: {
    categoryId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      models: null,
      category: null
    }
  },
  computed: {
    categoryModels() {
      return this.models.filter(m => {
        return m.categoryId === this.categoryId
      })
    }
  },
  methods: {
    ...mapActions(["fetchAllModels", "fetchAllCategories"])
  },
  created() {
    console.log("[CAT MODELS] loading all models...")
    this.fetchAllModels().then(models => {
      console.log("[CAT MODELS] models loaded", models)
      this.models = models
    })
    this.fetchAllCategories().then(categories => {
      this.category = categories.find(c => c.id === this.categoryId)
    })
  }
}
</script>

<style lang="stylus" scoped>
.flex_container {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  max-width: 1280px;
  min-height: 80vh;
  margin: auto;

  @media screen and (min-width: 600px) {
    padding: 0.5em 2em;
  }
}

.title {
  display: inline-flex;
}

.header {
  max-width: 600px;
  font-size: 2.5em;
  margin-top: 0.2em;
  margin-bottom: 0.5em;
  display: inline-flex;

  @media screen and (min-width: 600px) {
    margin-top: 2em;
  }
}

.results {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
