<template>
  <div class="tile_container tile" @click="showCategoryModels">
    <img class="icon" :src="categoryIcon" />
    <span class="label">{{ category.label.toUpperCase() }}</span>
  </div>
</template>

<script>
export default {
  name: "CategoryTile",
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  computed: {
    categoryIcon() {
      return require("@/assets/" + this.category.iconPath)
    }
  },
  methods: {
    showCategoryModels() {
      this.$router.push({
        name: "categoryModels",
        params: { categoryId: this.category.id }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.tile_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 200px;
  max-width: 40vw;
  height: 120px;
  margin: 10px;
  padding: 10px 0px;
  box-shadow: 1px 2px 3px 0 #000;
  transition-duration: 0.2s;

  &:hover {
    transform: scale(1.05);
  }
}

.icon {
  width: 70px;
}

.tile:hover {
  cursor: pointer;
}


.label {
  color: #5b5b5b;
}
</style>
