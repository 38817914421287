<template>
  <div class="tile_container tile" @click="toModel">
    <div class="header">
      <div class="label name" v-html="model.name"></div>
      <img class="producer-logo" :src="producerLogo" />
    </div>
    <img class="icon" :src="modelIcon" />
    <table class="description">
      <tr>
        <td>Producent:</td>
        <td v-html="model.producer"></td>
      </tr>
      <tr v-if="model.code">
        <td>Kod:</td>
        <td v-html="model.code.toUpperCase()"></td>
      </tr>
      <tr>
        <td>Czas składania:</td>
        <td>{{ timeConvert(model.time) }}</td>
      </tr>
      <tr>
        <td>Liczba kroków:</td>
        <td>{{ model.numberOfSteps }}</td>
      </tr>
    </table>

    <!-- <div class="button" @click="toModel">
      ZOBACZ ⮕
    </div> -->
  </div>
</template>

<script>
import { Model } from "@/data/models.js"

export default {
  name: "ModelThumbnailTile",
  props: {
    model: Model
  },
  methods: {
    timeConvert(n) {
      let num = n
      let hours = num / 60
      let rhours = Math.floor(hours)
      let minutes = (hours - rhours) * 60
      let rminutes = Math.round(minutes)
      if (rhours != 0) {
        return rhours + " h " + rminutes + " min"
      } else {
        return minutes + " min"
      }
    },
    toModel() {
      this.$router.push({
        path: `/model/${encodeURIComponent(this.model.id)}`
      })
    }
  },
  computed: {
    modelIcon() {
      return require("@/assets/" + this.model.image)
    },
    producerLogo() {
      return require("@/assets/" + this.model.producerLogo)
    }
  }
}
</script>

<style scoped lang="stylus">
.tile_container {
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: 50px auto;
  grid-template-areas: 'head head' 'ico des';
  width: 450px;
  min-height: 230px;
  margin: 15px;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 1px 2px 3px 0 #000;
  transition-duration: 0.2s;

  &:hover {
    transform: scale(1.03);
  }

  >.header {
    grid-area: head;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 7px;
    box-shadow: 0px 5px 5px 0px #dedede;

    >.producer-logo {
      height: 100%;
      max-width: 100px;
      flex: 0 0 auto;
    }

    >.name {
      font-weight: bold;
      font-size: 16px;
      color: #5b5b5b;
      margin-left: 10px;
      text-align: left;
    }
  }

  >.icon {
    grid-area: ico;
    margin-left: 20px;
    justify-self: center;
    align-self: center;
    max-width: 70%;
    max-height: 70%;
  }

  >.description {
    grid-area: des;
    justify-self: center;
    align-self: center;
    font-size: 14px;
    color: #5b5b5b;
    text-align: left;
    margin-bottom: 10px;

    >tr>td {
      padding: 3px;
    }

    >tr>td:first-child {
      font-weight: bold;
      padding-right: 10px;
    }
  }
}
</style>
