import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "@/store/store"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons'
import VTooltip from 'v-tooltip'

library.add(faEnvelope)
library.add(faSearch)
library.add(faLongArrowAltLeft)

Vue.config.productionTip = false
Vue.component('fa', FontAwesomeIcon)
Vue.use(VTooltip)

function detectIE() {
  var ua = window.navigator.userAgent
  var msie = ua.indexOf("MSIE ")
  if (msie > 0) {
    return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10)
  }
  var trident = ua.indexOf("Trident/")
  if (trident > 0) {
    var rv = ua.indexOf("rv:")
    return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10)
  }
  var edge = ua.indexOf("Edge/")
  if (edge > 0) {
    return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10)
  }
  return false
}

var version = detectIE()
if (!!version && version < 12) {
  document.getElementById("ieinfo").style.display = "block"
} else {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app")

  Vue.prototype.$minSearchChars = 3

  Vue.prototype.$window = window
  Vue.prototype.$ininCache = {}

  Vue.prototype.$transitionEvents = {
    pending: false,
    onCompleteListeners: [],
    onceComplete(listener) {
      if (!this.pending) listener()
      else {
        let once = () => {
          listener()
          this.onCompleteListeners = this.onCompleteListeners.filter(
            l => l != once
          )
        }
        this.onCompleteListeners.push(once)
      }
    },
    startTransition() {
      this.pending = true
    },
    finishTransition() {
      this.pending = false
      this.onCompleteListeners.forEach(l => l())
    }
  }
}
