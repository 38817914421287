import Vue from "vue"
import Router from "vue-router"
import PageHome from "@/views/PageHome"
import PageSearch from "@/views/PageSearch"
import PageCategoryModels from "@/views/PageCategoryModels"

Vue.use(Router)

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: PageHome,
      meta: { transitionOrder: 1 }
    },
    {
      path: "/search/:queryInput",
      name: "search",
      component: PageSearch,
      props: true,
      meta: { transitionOrder: 2 }
    },
    {
      path: "/model/:id",
      name: "model",
      props: true,
      component: () => import("./views/PageModel.vue"),
      meta: { transitionOrder: 4 }
    },
    {
      path: "/category/:categoryId",
      name: "categoryModels",
      component: PageCategoryModels,
      props: true,
      meta: { transitionOrder: 3 }
    }
  ]
})

Vue.prototype.$history = {
  stepsBack: 0,
  stepsForward: 0,
  fromHistory: false,
  canGoBack() {
    return this.stepsBack > 0
  },
  canGoForward() {
    return this.stepsForward > 0
  },
  back(fallback) {
    if (this.canGoBack()) {
      this.stepsBack -= 1
      this.stepsForward += 1
      this.fromHistory = true
      router.back()
    } else if (fallback) {
      router.push({ path: "/" })
    }
  },
  forward() {
    if (this.canGoForward()) {
      this.stepsForward -= 1
      this.fromHistory = true
      router.forward()
    }
  },
  navigationOccured(difference) {
    if (difference > 0) {
      if (!this.fromHistory) {
        this.stepsForward = 0
      }
      this.stepsBack += 1
    } else if (difference < 0) {
      if (!this.fromHistory) {
        this.stepsForward = 0
        this.stepsBack = 0
      }
    }
    this.fromHistory = false
  }
}

export default router
